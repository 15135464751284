@import '~@ebay/skin/tokens.css';
@import '~@ebay/skin/less.less';

.appointment {
  padding-right: 5px;
  position: relative;

  &__date {
    margin: 10px 0 5px 0;
    .section__title {
      font-size: @font-size-16;
    }

    &-calendar {
      margin-top: 10px;
      padding-top: 5px;
    }
  }
}

@media (max-width: @_screen-size-MD) {
  .appointment {
    padding-right: 5px;
  }
}
