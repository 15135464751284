@import '~@ebay/skin/tokens.css';
@import '~@ebay/skin/less.less';

.vas-tire-installation {
  .header-title {
    .typography-large-1();
  }

  &__left {
    min-width: 424px;
    margin-right: 15px;
    width: 50%;
  }

  &__right {
    min-width: 370px;
    width: 50%;
    display: flex;
    flex-direction: column;
  }

  &__radius-sort,
  &__content,
  &__installer-form {
    display: flex;
    justify-content: space-between;
  }

  &__content {
    padding: 0 16px;
  }

  &__radius-sort,
  &__installer-form {
    align-items: baseline;
  }

  &.vas-tire-installation__appointment {
    .vas-tire-installation__left {
      height: 700px;
      overflow-y: hidden;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;

      .installer-list {
        height: 100%;
        overflow: scroll;
        padding-right: 0;
      }

      .loader {
        height: 728px;
      }
    }
  }
}
