@import '~@ebay/skin/tokens.css';
@import '~@ebay/skin/less.less';

.vas-warranty-interstitial-nudge-d {
  margin: @spacing-300;
  font-size: 0.875rem;

  a,
  a:focus,
  a:visited {
    color: var(--color-blue-4);
    text-decoration: underline;
  }

  .vas-interstitial-error {
    min-height: 6rem;

    &-notice {
      p {
        color: #111820;
        font-weight: 500 !important;
      }
    }
  }

  .vas-interstitial-header {
    display: flex;
    flex-shrink: 0;
    position: relative;
    align-items: center;

    &-text {
      flex: 1 1 auto;
      font-size: 1.25rem;
      color: #111820;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-close-btn {
      height: 40px;
      width: 40px;
      border: none;
      background-color: var(--color-neutral-1);
      border-radius: 50%;

      svg.icon.icon--close-16 {
        fill: #111820;
      }
    }
  }

  .vas-interstitial-items {
    margin-bottom: @spacing-200;

    .vas-interstitial-item {
      display: flex;
      justify-content: space-between;

      &-left {
        width: 85%;
      }

      &-label {
        line-height: 24px;
        color: #000000;
        font-size: @spacing-200;
        margin-bottom: @spacing-50;
      }
      &-secondaryLabel {
        line-height: 20px;
        color: #111820;
        margin-bottom: @spacing-50;
      }
      &-tertiaryLabel {
        line-height: 20px;
        color: #111820;
        margin-bottom: @spacing-100;
      }
      &-quaternaryLabel {
        line-height: 20px;
        color: #111820;
        margin-bottom: @spacing-100;
      }
      .link-label {
        margin-top: 0.75rem;
      }
    }
  }

  .vas-interstitial-vault-cta {
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
    bottom: @spacing-300;
    right: @spacing-300;

    &-btn {
      margin-left: @spacing-200;
      padding: 0px @spacing-200 0px @spacing-200;
    }
  }

  .vas-body-heading {
    margin-top: @spacing-50;
    margin-bottom: @spacing-50;
  }

  .vas-heading {
    margin-top: @spacing-200;
    margin-bottom: @spacing-200;
    display: flex;
    align-items: center;
  }

  .icon-vault {
    background-image: url(https://ir.ebaystatic.com/cr/v/c01/swc-updated-sprite-0223.png);
    background-position: center;
    background-position-y: -427px;
    height: 25px;
    width: @spacing-300;
    display: inline-block;
    background-size: 44px;
    margin: 0;
  }

  .heading-text {
    margin-left: @spacing-50;
  }

  .vas-divider {
    border-top: 1px solid var(--color-neutral-2);
    border-bottom: 1px solid var(--color-neutral-2);
  }

  .vas-secondary-labels {
    margin-top: @spacing-200;
    margin-bottom: @spacing-200;
  }

  .field {
    display: flex;
    align-items: center;
    .field__label {
      margin-left: @spacing-200;
    }
  }

  .vas-spoke-item-card {
    .vas-itm-card {
      margin-left: -15px;
      margin-top: -32px;
      .vas-itm-card__title {
        color: var(--color-neutral-7);
        font-weight: @font-weight-regular;
      }
    }
    .vas-itm-card__image {
      width: 48px;
      height: 48px;
    }
  }

  .field-secondory-label {
    margin-left: 2.15rem;
    margin-bottom: @spacing-200;
  }

  .vault-interstitial-cta {
    margin-top: @spacing-100;
    float: right;
  }

  .spoke-body-primary {
    display: flex;
  }

  .vas-spoke-body-primary-left {
    flex: 1 1 auto;
  }

  .vas-interstitial-footer {
    margin-top: @spacing-200;
  }

  .high-asp {
    .section-notice {
      display: block;
      .section-notice__header {
        height: auto;
      }
    }
  }
}
