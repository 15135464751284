@import '~@ebay/skin/less.less';

.vas-tire-installation__installation_v2 {

  .installer-card {

    .installer-card__name {
      line-height: 20px;
      font-size: @font-size-14;
    }

    .installer-card__dropoff__date-time {
      font-size: @font-size-12;
      line-height: 16px;
    }

    .installer-card__hours {
      margin: 3px 0 8px 0;
    }

    .installer-card__dropoff__date-time:last-child span {
      font-weight: 700;
    }

    .installer-card__accessory-label {
      font-size: @font-size-12;

      .ux-textspans--BOLD:first-child {
        font-weight: 700;
      }
    }

    .installer-card__index {
      display: none;
    }

    .ux-textspans--BOLD {
      font-weight: 400;
    }
  }
}

.installer-card {

  .installer-card {
    &__address span {
      display: block;
      .typography-small();
    }
  }

  &__dropoff__date-time span {
    display: block;
  }

  &__columns {
    display: flex;
    justify-content: space-between;
  }

  &__name {
    .typography-medium();
  }

  &__accessory-label {
    padding: 0 8px;
    text-align: right;
    white-space: nowrap;

    span:first-child span {
      color: var(--color-neutral-7);
      font-weight: normal;
    }

    span {
      color: var(--color-neutral-5); // no var matches figma #767676
      display: block;
      font-size: @font-size-12;
      line-height: 20px;
    }
  }

  &__hours {
    margin: 8px 0;
    display: block;

    .typography-small-secondary();

    span {
      display: block;
    }

    &-title {
      display: none;
    }
  }

  &__dropoff {
    padding-top: 8px;
  }
}
