@keyframes animationname {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

.animateMixins() {
  background: linear-gradient(to right, #f2f2f2 4%, #f8f8f8 25%, #f2f2f2 36%);
  -webkit-animation: animationname 2s ease infinite;
  -moz-animation: animationname 2s ease infinite;
  animation: animationname 2s ease infinite;
  background-size: 1000px 100%;
}

.loader {
  width: 100%;

  &__list {
    width: 100%;
    background-color: #e5e5e5;
    border: 1px solid transparent;
    border-radius: 4px;
    height: 90px;
    margin-bottom: 20px;

    &:last-child {
      margin: 0;
    }

    .animateMixins();
  }
}
