@import '~@ebay/skin/less.less';
@import '~@ebay/skin/tokens.css';

.postal-code {
  input {
    width: 178px;
  }

  &__error-message {
    margin: 0;
    padding: 0;
    color: var(--floating-label-invalid-color, var(--color-foreground-attention));

    .typography-small();
  }
}

@media (max-width: 767px) {
  .postal-code {
    margin: 16px 0;

    input {
      width: 100%;
    }
  }
}
