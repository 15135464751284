@import './common.less';

/** DESKTOP **/
.tires-map {
  &__root {
    margin-bottom: 16px;
  }

  &__google-map {
    aspect-ratio: 95 / 67;
  }

  &__root,
  &__google-map {
    border: 1px solid transparent;
    border-radius: 8px;
  }

  &__container {
    border-radius: 50%;
    height: 24px;
    line-height: 25px;
    width: 24px;
  }

  &__num {
    display: block;
    flex: 1;
    overflow: hidden;
  }

  &__marker > input&__input {
    &:checked + label.tires-map__container {

      .typography-medium-bold();

      width: 40px;
      height: 40px;
      line-height: 40px;
    }
  }
}
