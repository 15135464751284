@import '~@ebay/skin/tokens.css';
@import '~@ebay/skin/less.less';

.tires-map {
  &__root {
    margin: 0;
    padding: 0;
  }

  &__google-map {
    width: 100%;
  }

  &__installer-list {
    display: none;
  }

  &__marker {
    position: relative;
    z-index: 1;

    &&--selected {
      z-index: 2;
    }

    &:hover,
    &:focus {
      z-index: 3;
    }
  }
  &__marker > input&__input {
    display: block;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
    z-index: 1;

    &:checked + label.tires-map__container {
      background-color: var(--color-blue-4);
      color: var(--color-neutral-0);
    }
  }
  &__container {
    background-color: var(--color-neutral-0);
    border-radius: var(--border-radius-50);
    box-sizing: border-box;
    display: flex;
    filter: drop-shadow(0px 2px 7px rgb(0 0 0 / 15%)) drop-shadow(0px 5px 17px rgb(0 0 0 / 20%));
    flex-direction: column;
    text-align: center;
  }
  &__num {
    display: none;
    font-weight: 700;
  }
  &__label {
    display: none;
  }
  &__accessory-label-0 {
    display: none;
  }
  &__accessory-label-1 {
    display: none;
  }
}
