@import '~@ebay/skin/less.less';
@import '~@ebay/skin/tokens.css';
@vas-ti-secondary-text-color: #5E5E5E;

.item-card {
  background-color: var(--color-neutral-1);
  border: 1px;
  border-radius: var(--border-radius-50);
  display: flex;
  justify-content: space-between;
  padding: 15px 15px 10px 15px;
  align-items: flex-start;

  .item-card__right-content .ux-textspans--SECONDARY {
    color: @vas-ti-secondary-text-color;
  }

  &__image {
    margin-right: 16px;
    overflow: hidden;
    width: 40px;
    height: 40px;

    img {
      width: 40px;
      height: 40px;
    }
  }

  // TODO: Remove skin over-ride
  svg.icon--photo-gallery {
    height: 40px;
    width: 40px;
  }

  &__right-content {
    flex: 1;
  }

  &__title {
    margin: 0;
    font-weight: @font-weight-regular;
  }

  &__secondary-text {
    margin: 0;
  }

  &__title,
  &__secondary-text {
    .typography-small();
  }

  &__below-section {
    margin-top: 5px;

    .section__title {
      padding-right: 5px;
    }

    .section__dataitems {
      display: inline;
    }

    .section__dataitems {
      span:not(:first-child) {
        display: block;
      }
    }
  }
}
