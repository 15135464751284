@import '~@ebay/skin/less.less';
@import '~@ebay/skin/tokens.css';

.find-installer {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  margin-right: 10px;
  width: 100%;

  &__installation-label {
    margin: 0;
    padding: 0;
  }

  .find-installer__radius-dropdown {
    width: 40%;

    .floating-label, span.select, select {
      width: 100%;
    }

    &__error-message {
      margin: 0;
      padding: 0;
      color: var(--floating-label-invalid-color, var(--color-foreground-attention));

      .typography-small();
    }
  }

  .find-installer__chevron {
    margin-left: auto;
  }
}
