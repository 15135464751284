@import '~@ebay/skin/less.less';
@import '~@ebay/skin/tokens.css';
.card-tile {
  padding: 20px 0;
  &__cards {
    padding: 20px 0;
  }
  &__card {
    display: flex;
    align-items: center;
    padding: 0 0 20px;

    &-left {
      background-color: var(--color-background-secondary);
      border-radius: 50%;
      color: var(--color-neutral-8);
      height: 60px;
      line-height: 60px;
      min-width: 60px;
      text-align: center;
      width: 60px;
      font-size: @font-size-large-2;
      font-weight: @font-weight-bold;
      margin-right: 20px;
    }
  }
}
