@import '~@ebay/skin/less.less';
@import '~@ebay/skin/tokens.css';

.vas-itm-card {
  border: 1px;
  display: flex;
  justify-content: space-between;
  padding: @spacing-200;
  align-items: center;
  order: 1;
  flex-grow: 1;

  &__image {
    width: 80px;
    height: 80px;
    position: relative;
    vertical-align: middle;
    text-align: center;
    border: 1px solid var(--color-neutral-2);
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      word-break: break-all;
      vertical-align: middle;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }

  &__right-content {
    margin-left: @spacing-200;
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__title {
    margin: 0;
    font-weight: @font-weight-regular;
  }

  &__secondary-text {
    margin: 0;
  }

  &__secondary-text {
    .typography-small();
  }
}
