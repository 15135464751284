@import '~@ebay/skin/tokens.css';

.installer-list {
  width: 100%;

  .filter-menu {
    width: 100%;

    .filter-menu__items {
      margin-top: 0px;
    }

    &__radio {
      padding: 0 8px;
    }

    &__item[role="menuitemradio"] {
      border-bottom: 1px solid var(--color-neutral-2);
      padding: 16px 0;
    }
    .filter-menu__items > div:first-child {
      border-top: 1px solid var(--color-neutral-2);
    }
  }
}
