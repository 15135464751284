@import '~@ebay/skin/tokens.css';
.installer-list {
  width: 100%;
}
.installer-list .filter-menu {
  width: 100%;
}
.installer-list .filter-menu .filter-menu__items {
  margin-top: 0px;
}
.installer-list .filter-menu__radio {
  padding: 0 8px;
}
.installer-list .filter-menu__item[role="menuitemradio"] {
  border-bottom: 1px solid var(--color-neutral-2);
  padding: 16px 0;
}
.installer-list .filter-menu .filter-menu__items > div:first-child {
  border-top: 1px solid var(--color-neutral-2);
}
.installer-list {
  height: 390px;
  overflow-y: scroll;
  padding-right: 16px;
}
