@import '~@ebay/skin/tokens.css';
@import '~@ebay/skin/less.less';

.vas-provider {
  border-top: 1px solid var(--color-neutral-2);
  padding: 7px 0 0 0;
  .typography-small();
  .vas-provider__image {
    align-items: center;
    height: 35px;
    overflow: hidden;
    display: flex;
  }

  img {
    max-width: 95px;
    max-height: 95px;
    vertical-align: middle
  }
}
