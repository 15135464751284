@import './common.less';

.whats-included {
  margin-bottom: 16px;

  &__title {
    position: relative;
    top: 18px;

    .ux-textspans--BOLD {
      .typography-medium-bold();
    }

    .whats-included__sub-heading {
      .typography-small-secondary();
      padding: 5px 0;
    }

    span {
      display: block;
    }
  }

  &__details {
    text-align: right;
    position: relative;
    bottom: 3px;
  }
}

.vas-tire-installation__installation_v2 {

  .whats-included {
    border-bottom: 0;
    padding-bottom: 15px;
    .typography-small();

    .whats-included__details {
      top: 30px;
      position: relative;
      text-align: left;

      p {
        padding-left: 10px
      }

      summary.details__summary {
        .typography-regular();
        display: block;
        color: var(--color-neutral-7);
      }
    }
  }
}
