@import '~@ebay/skin/tokens.css';
.tires-map__root {
  margin: 0;
  padding: 0;
}
.tires-map__google-map {
  width: 100%;
}
.tires-map__installer-list {
  display: none;
}
.tires-map__marker {
  position: relative;
  z-index: 1;
}
.tires-map__marker.tires-map__marker--selected {
  z-index: 2;
}
.tires-map__marker:hover,
.tires-map__marker:focus {
  z-index: 3;
}
.tires-map__marker > input.tires-map__input {
  display: block;
  height: 100%;
  opacity: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.tires-map__marker > input.tires-map__input:checked + label.tires-map__container {
  background-color: var(--color-blue-4);
  color: var(--color-neutral-0);
}
.tires-map__container {
  background-color: var(--color-neutral-0);
  border-radius: var(--border-radius-50);
  box-sizing: border-box;
  display: flex;
  filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.15)) drop-shadow(0px 5px 17px rgba(0, 0, 0, 0.2));
  flex-direction: column;
  text-align: center;
}
.tires-map__num {
  display: none;
  font-weight: 700;
}
.tires-map__label {
  display: none;
}
.tires-map__accessory-label-0 {
  display: none;
}
.tires-map__accessory-label-1 {
  display: none;
}
/** DESKTOP **/
.tires-map__root {
  margin-bottom: 16px;
}
.tires-map__google-map {
  aspect-ratio: 95 / 67;
}
.tires-map__root,
.tires-map__google-map {
  border: 1px solid transparent;
  border-radius: 8px;
}
.tires-map__container {
  border-radius: 50%;
  height: 24px;
  line-height: 25px;
  width: 24px;
}
.tires-map__num {
  display: block;
  flex: 1;
  overflow: hidden;
}
.tires-map__marker > input.tires-map__input:checked + label.tires-map__container {
  font-size: var(--font-size-medium);
  line-height: 24px;
  font-weight: var(--font-weight-bold);
  width: 40px;
  height: 40px;
  line-height: 40px;
}
